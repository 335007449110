import api from '~/services/api';
import { Serie, SelectType } from '../../protocols';

export async function getFinalizadora(codLoja: number): Promise<SelectType[]> {
  const res = await api.get(`/finalizadora-loja/${codLoja}`);
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }
  const options = data.map((item: any) => {
    return {
      value: item.finalizadora.cod_finalizadora,
      label: `${item.finalizadora.cod_finalizadora} - ${item.finalizadora.des_finalizadora}`,
      ...item.finalizadora,
    };
  });

  return options;
}
