import React from 'react';
import { Capa } from './Capa';
import { Itens } from './Itens';

export const Nfe: React.FC = () => {
  return (
    <>
      <Capa />
      <Itens />
    </>
  );
};
