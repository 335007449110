import React from 'react';
import Separator from '~/components/Separator';

import { Row, Col } from 'react-bootstrap';
import {
  InputMoney,
  InputNumber,
  InputPeso,
  InputText,
} from '~/components/NovosInputs';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';

export const FormExportador: React.FC = () => {
  const { register, isUpdate, control } = useDevolucaoSaidaNFEForn();

  return (
    <>
      <Separator labelText="Transporte" sidePadding="0 0" />

      <Row>
        <Col md={2} sm={12}>
          <InputMoney
            label="Seguro"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_seguro"
            disabled={isUpdate}
            register={register}
            isError={false}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputNumber
            label="Qtd. Emb."
            maxLength={5}
            max={150}
            min={0}
            placeholder="0"
            disabled={isUpdate}
            name="qtd_embalagem"
            register={register}
            isError={false}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputPeso
            label="Peso Liq."
            placeholder="0,00"
            decimals={2}
            disabled={isUpdate}
            name="qtd_peso_liq"
            register={register}
            control={control}
            isError={false}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputPeso
            label="Peso Bruto"
            placeholder="0,00"
            decimals={2}
            disabled={isUpdate}
            name="qtd_peso_bruto"
            control={control}
            register={register}
            isError={false}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputText
            label="Marca Veic."
            maxLength={50}
            placeholder=""
            disabled={isUpdate}
            name="des_marca_veiculo"
            register={register}
            isError={false}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputText
            label="Placa Veic."
            maxLength={50}
            placeholder=""
            name="des_placa_veiculo"
            register={register}
            disabled={isUpdate}
            isError={false}
          />
        </Col>
      </Row>
    </>
  );
};
