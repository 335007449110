import styled from 'styled-components';

export const Button = styled.button`
  float: right;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: 0.4s;
  color: #424242;
  &:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  &:focus {
    outline: 0;
  }
  svg {
    color: #eda934 !important;
  }
`;
