import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { InputTextArea } from '~/components/NovosInputs';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';

export const FormObservacao: React.FC = () => {
  const { register, control, isUpdate } = useDevolucaoSaidaNFEForn();

  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <InputTextArea
            maxLength={500}
            label="Observação"
            disabled={isUpdate}
            name="des_obs_livre"
            register={register}
            control={control}
            isError={false}
          />
        </Col>
      </Row>
    </>
  );
};
