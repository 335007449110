import api from '~/services/api';
import { Cfop } from '../../protocols';

export async function getCfops(codPerfil: string | number | undefined) {
  if (codPerfil) {
    try {
      const res = await api.get(`/perfil_cfop/${codPerfil}`);
      const { success, data, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const options = data.map((cfop: Cfop) => {
        return {
          value: cfop.cfop,
          label: `${cfop.cfop} - ${cfop.des_cfop}`,
          cod_id_ctb: cfop.cod_id_ctb,
          cfop: cfop.cfop,
          flg_bonif: cfop.flg_bonif,
          flg_separa_subst: cfop.flg_separa_subst,
          flg_dif: cfop.flg_dif,
        };
      });
      return options;
    } catch (e: any) {
      console.error(e);
    }
  }
}
