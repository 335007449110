import { toast } from 'react-toastify';
import api from '~/services/api';

export async function getLojaUf(loja: number): Promise<string | null> {
  try {
    const { data } = await api.get(`/lojas/${loja}`);
    if (!data.data) {
      toast.warn('Não foi possível encontrar a UF da loja selecionada');
      return null;
    }
    return data.data.des_uf;
  } catch (error: any) {
    let strError = '';
    if (error.message) strError = String(error.message);
    if (error.data.message) strError = String(error.data.message);
    toast.error(`Erro ao buscar UF da loja selecionada: ${strError}`);
    return null;
  }
}
